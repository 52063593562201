import React, { useState } from "react";
import Axios from "axios";

export default function Form() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const url = "https://admin.iik-luumha.com/api/leadapi";

  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    clientNotes: "",
    productName: "Iik - LuumHa",
  });

  function submit(e) {
    e.preventDefault();
    Axios.post(url, {
      name: data.name,
      phone: data.phone,
      email: data.email,
      city: data.city,
      clientNotes: data.clientNotes,
      productName: "Iik - LuumHa - Web",
    }).then((res) => {
      console.log(res.data);
      handleShow();
    });
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  return (
    <form
      className="bg-white rounded-xl drop-shadow-xl p-10 mt-5"
      onSubmit={(e) => submit(e)}
    >
      <p className="text-4xl font-bold text-center text-yellow-900">
        Contáctanos
      </p>
      <p className="p-3 text-center">
        Llena el siguiente formulario y en breve uno de nuestros asesores se
        comunicará con usted.
      </p>
      <div className="grid grid-cols-1 my-3">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Nombre completo
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="text"
            name="name"
            id="name"
            className="focus:ring-blue-300 focus:border-blue-300 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
            placeholder="Ej. Juan Perez"
            onChange={(e) => handle(e)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 my-3">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Correo electrónico
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="email"
            name="email"
            id="email"
            className="focus:ring-blue-300 focus:border-blue-300 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
            placeholder="Ej. juan@correo.com"
            onChange={(e) => handle(e)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 my-3">
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-700"
        >
          Teléfono a 10 dígitos
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="tel"
            name="phone"
            id="phone"
            className="focus:ring-blue-300 focus:border-blue-300 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
            placeholder="Ej. 5522114477"
            onChange={(e) => handle(e)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 my-3">
        <label
          htmlFor="city"
          className="block text-sm font-medium text-gray-700"
        >
          Ciudad
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="text"
            name="city"
            id="city"
            className="focus:ring-blue-300 focus:border-blue-300 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
            placeholder="Ej. Ciudad de México"
            onChange={(e) => handle(e)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 my-3">
        <label
          htmlFor="notes"
          className="block text-sm font-medium text-gray-700"
        >
          Mensaje
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <textarea
            id="notes"
            name="notes"
            maxrows={3}
            className="shadow-sm focus:ring-blue-300 focus:border-blue-300 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
            placeholder="Escriba su mensaje"
            defaultValue={""}
            onChange={(e) => handle(e)}
          />
        </div>
      </div>

      <button
        type="submit"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 w-full"
      >
        Enviar formulario
      </button>
      <p
        class={
          "mt-2 text-sm text-green-600 dark:text-green-500 " +
          (show == true ? " show " : " hidden ")
        }
      >
        <span class="font-medium">Gracias,</span> en breve uno de nuestros
        asesores se comunicará con usted.
      </p>
    </form>
  );
}
