import React from "react";
import MasterplanTitle from "./MasterplanTitle";
import Financiamiento from "./Financiamiento";
import TerrainList from "./TerrainList";

export default function Masterplan() {
  return (
    <div
      className="flex flex-wrap overflow-hidden justify-center h-screen		"
      id="masterplan"
    >
      <iframe
        src="https://admin.iik-luumha.com/planmaestroactualizado/1"
        width="100%"
        height="100%"
        seamless="seamless"
        scrolling="no"
        frameBorder="0"
        title="masterplan"
        allowfullscreen
      ></iframe>
    </div>
  );
}
